import { Box, Button, Container, Paper } from "@mui/material";
import { ReactElement } from "react";

interface LoginPageProps {
  onLoginClick: () => void;
}

export default function LoginPage(props: LoginPageProps): ReactElement {
  return (
    <Container component="main" maxWidth="xs">
      <Paper style={{ padding: "1rem", marginTop: "3rem" }} elevation={6}>
        <Box my="3rem">
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={props.onLoginClick}
          >
            Login to the Application
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
