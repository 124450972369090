import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "date-fns";
import { addDays } from "date-fns";
import { ReactElement, useEffect, useState } from "react";
import { apiGetQuarterParams, apiSetQuarterParams } from "../api";
import AlertDialog from "../Components/AlertDialog";

export default function QuartersPage(): ReactElement {
  const year = new Date().getFullYear();

  const [isLoading, setIsLoading] = useState(false);

  const [quarter1StartDate, setQuarter1StartDate] = useState(
    new Date(year, 2, 15)
  );
  const [quarter2StartDate, setQuarter2StartDate] = useState(
    new Date(year, 5, 15)
  );
  const [quarter3StartDate, setQuarter3StartDate] = useState(
    new Date(year, 8, 15)
  );
  const [quarter4StartDate, setQuarter4StartDate] = useState(
    new Date(year, 11, 15)
  );

  const [quarter1Len, setQuarter1Len] = useState(30);
  const [quarter2Len, setQuarter2Len] = useState(30);
  const [quarter3Len, setQuarter3Len] = useState(30);
  const [quarter4Len, setQuarter4Len] = useState(30);

  const [quarter1MinDate] = useState(new Date(year, 0, 1));
  const [quarter2MinDate, setQuarter2MinDate] = useState<Date>();
  const [quarter3MinDate, setQuarter3MinDate] = useState<Date>();
  const [quarter4MinDate, setQuarter4MinDate] = useState<Date>();

  const [quarter1EndDate, setQuarter1EndDate] = useState("");
  const [quarter2EndDate, setQuarter2EndDate] = useState("");
  const [quarter3EndDate, setQuarter3EndDate] = useState("");
  const [quarter4EndDate, setQuarter4EndDate] = useState("");

  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const showMessage = (title: string, message: string) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    apiGetQuarterParams()
      .then((data) => {
        setIsLoading(false);

        setQuarter1StartDate(new Date(data.q1));
        setQuarter2StartDate(new Date(data.q2));
        setQuarter3StartDate(new Date(data.q3));
        setQuarter4StartDate(new Date(data.q4));

        setQuarter1Len(data.q1Len);
        setQuarter2Len(data.q2Len);
        setQuarter3Len(data.q3Len);
        setQuarter4Len(data.q4Len);
      })
      .catch((e) => {
        setIsLoading(false);

        showMessage("Error", e.message);
      });
  }, []);

  useEffect(() => {
    const endDate: Date = addDays(quarter1StartDate, quarter1Len);
    const minDate: Date = addDays(quarter1StartDate, quarter1Len + 1);
    setQuarter1EndDate(endDate.toLocaleDateString());
    setQuarter2MinDate(minDate);
  }, [quarter1StartDate, quarter1Len]);

  useEffect(() => {
    const endDate: Date = addDays(quarter2StartDate, quarter2Len);
    const minDate: Date = addDays(quarter2StartDate, quarter2Len + 1);
    setQuarter2EndDate(endDate.toLocaleDateString());
    setQuarter3MinDate(minDate);
  }, [quarter2StartDate, quarter2Len]);

  useEffect(() => {
    const endDate: Date = addDays(quarter3StartDate, quarter3Len);
    const minDate: Date = addDays(quarter3StartDate, quarter3Len + 1);
    setQuarter3EndDate(endDate.toLocaleDateString());
    setQuarter4MinDate(minDate);
  }, [quarter3StartDate, quarter3Len]);

  useEffect(() => {
    const endDate: Date = addDays(quarter4StartDate, quarter4Len);
    setQuarter4EndDate(endDate.toLocaleDateString());
  }, [quarter4StartDate, quarter4Len]);

  const saveChanges = () => {
    let q1 = new Date(quarter1StartDate);
    let q2 = new Date(quarter2StartDate);
    let q3 = new Date(quarter3StartDate);
    let q4 = new Date(quarter4StartDate);

    q1.setHours(22, 30);
    q2.setHours(22, 30);
    q3.setHours(22, 30);
    q4.setHours(22, 30);

    setIsLoading(true);
    apiSetQuarterParams({
      q1,
      q2,
      q3,
      q4,
      q1Len: quarter1Len,
      q2Len: quarter2Len,
      q3Len: quarter3Len,
      q4Len: quarter4Len,
    })
      .then(() => {
        setIsLoading(false);
        showMessage("Success", "Your changed have been saved.");
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e.message);
        showMessage(
          "Error",
          "Error while trying to save changes. Please try again."
        );
      });
  };

  return (
    <Container maxWidth="md" style={{ marginTop: 30 }}>
      <AlertDialog
        title={alertTitle}
        message={alertMessage}
        isOpen={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />

      <Backdrop open={isLoading} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>

      <Paper elevation={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box p={2}>
            <Grid
              container
              spacing={3}
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={4}>
                <DatePicker
                  minDate={quarter1MinDate}
                  format="MM/dd/yyyy"
                  label="Q1 Start Date"
                  value={quarter1StartDate}
                  onChange={(date: Date | null) => {
                    if (date) {
                      setQuarter1StartDate(date);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" gutterBottom>
                  Quarter Length in Days
                </Typography>
                <Slider
                  valueLabelDisplay="auto"
                  style={{ top: "15px" }}
                  value={quarter1Len}
                  onChange={(e, val) => setQuarter1Len(val as number)}
                  min={1}
                  max={90}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Quarter End Date"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  value={quarter1EndDate}
                />
              </Grid>

              <Grid item xs={4}>
                <DatePicker
                  minDate={quarter2MinDate}
                  //minDateMessage="Date should not be before Q1 end date"
                  format="MM/dd/yyyy"
                  label="Q2 Start Date"
                  value={quarter2StartDate}
                  onChange={(date: Date | null) => {
                    if (date) {
                      setQuarter2StartDate(date);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" gutterBottom>
                  Quarter Length in Days
                </Typography>
                <Slider
                  valueLabelDisplay="auto"
                  style={{ top: "15px" }}
                  value={quarter2Len}
                  onChange={(e, val) => setQuarter2Len(val as number)}
                  min={1}
                  max={90}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Quarter End Date"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  value={quarter2EndDate}
                />
              </Grid>

              <Grid item xs={4}>
                <DatePicker
                  minDate={quarter3MinDate}
                  //minDateMessage="Date should not be before Q2 end date"
                  format="MM/dd/yyyy"
                  label="Q3 Start Date"
                  value={quarter3StartDate}
                  onChange={(date: Date | null) => {
                    if (date) {
                      setQuarter3StartDate(date);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" gutterBottom>
                  Quarter Length in Days
                </Typography>
                <Slider
                  valueLabelDisplay="auto"
                  style={{ top: "15px" }}
                  value={quarter3Len}
                  onChange={(e, val) => setQuarter3Len(val as number)}
                  min={1}
                  max={90}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Quarter End Date"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  value={quarter3EndDate}
                />
              </Grid>

              <Grid item xs={4}>
                <DatePicker
                  minDate={quarter4MinDate}
                  //minDateMessage="Date should not be before Q3 end date"
                  format="MM/dd/yyyy"
                  label="Q4 Start Date"
                  value={quarter4StartDate}
                  onChange={(date: Date | null) => {
                    if (date) {
                      setQuarter4StartDate(date);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" gutterBottom>
                  Quarter Length in Days
                </Typography>
                <Slider
                  valueLabelDisplay="auto"
                  style={{ top: "15px" }}
                  value={quarter4Len}
                  onChange={(e, val) => setQuarter4Len(val as number)}
                  min={1}
                  max={90}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Quarter End Date"
                  fullWidth
                  InputProps={{ readOnly: true }}
                  value={quarter4EndDate}
                />
              </Grid>
            </Grid>
          </Box>
        </LocalizationProvider>

        <Divider />

        <Box p={2}>
          <Grid container justifyContent="center">
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                onClick={saveChanges}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
