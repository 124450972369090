import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ReactElement } from "react";

interface AlertDialogProps {
  title: string;
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function AlertDialog(props: AlertDialogProps): ReactElement {
  return (
    <Dialog maxWidth="sm" fullWidth={true} open={props.isOpen}>
      <DialogTitle>{props.title}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
