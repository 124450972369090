import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  PublicClientApplication,
} from "@azure/msal-browser";

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID || "",
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
};

const scopes: string[] = process.env.REACT_APP_AZURE_LOGIN_SCOPE
  ? [process.env.REACT_APP_AZURE_LOGIN_SCOPE]
  : [];

export const msalInstance = new PublicClientApplication(msalConfig);

export async function getAccessToken(): Promise<string> {
  const accounts: AccountInfo[] = msalInstance.getAllAccounts();
  if (!accounts.length) {
    throw new Error("MSAL: There are no accounts present!");
  }

  const result: AuthenticationResult = await msalInstance.acquireTokenSilent({
    account: accounts[0],
    scopes: scopes,
  });

  return result.accessToken;
}
