import MaterialTable, { Filter } from "@material-table/core";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TablePagination,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import {
  apiExportAuthorize,
  apiFormExportEmployeesUrl,
  apiGetEmployees,
  apiGetYears,
  Employee,
} from "../api";
import "./EmployeesPage.css";

export default function EmployeesPage(): ReactElement {
  const [isLoadingYears, setIsLoadingYears] = useState(false);
  const [isLoadingEmployees, setIsLoadingEmployees] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [years, setYears] = useState<string[]>([]);
  const [year, setYear] = useState("");

  const [currentQuarter, setCurrentQuarter] = useState<string>("");

  const [employees, setEmployees] = useState<Employee[]>([]);

  const [empCodeFilter, setEmpCodeFilter] = useState("");
  const [empNameFilter, setEmpNameFilter] = useState("");
  const [empDivisionFilter, setEmpDivisionFilter] = useState("");
  const [empQuarterFilter, setEmpQuarterFilter] = useState("");
  const [empHasAcknowledgedFilter, setEmpHasAcknowledgedFilter] = useState("");
  const [empAckDateFilter, setEmpAckDateFilter] = useState<Date>();

  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    const updateWindowHeight = () => setWindowHeight(window.innerHeight);

    updateWindowHeight();

    window.addEventListener("resize", updateWindowHeight);

    return () => window.removeEventListener("resize", updateWindowHeight);
  }, []);

  useEffect(() => {
    setIsLoadingYears(true);
    apiGetYears()
      .then((data) => {
        setIsLoadingYears(false);
        setErrorMessage("");

        setYears(data.years);
        setCurrentQuarter(data.currentQuarter);

        if (data.years.length > 0) {
          setYear(data.years[0]);
        }
      })
      .catch((e) => {
        setIsLoadingEmployees(false);
        setErrorMessage(e.message);
      });
  }, []);

  useEffect(() => {
    setIsLoadingEmployees(true);
    apiGetEmployees(year)
      .then((data) => {
        setIsLoadingEmployees(false);
        setErrorMessage("");

        setEmployees(data.employees);

        // MaterialTable resets filters on data change without invoking onFilterChange
        setEmpCodeFilter("");
        setEmpNameFilter("");
        setEmpDivisionFilter("");
        setEmpQuarterFilter("");
        setEmpHasAcknowledgedFilter("");
        setEmpAckDateFilter(undefined);
      })
      .catch((e) => {
        setIsLoadingEmployees(false);
        setErrorMessage(e.message);
      });
  }, [year]);

  const onFilterChange = (filters: Filter<Employee>[]) => {
    for (const filter of filters) {
      switch (filter.column.field) {
        case "code":
          setEmpCodeFilter(filter.value);
          break;
        case "name":
          setEmpNameFilter(filter.value);
          break;
        case "division":
          setEmpDivisionFilter(filter.value);
          break;
        case "currentQuarter":
          setEmpQuarterFilter(filter.value);
          break;
        case "hasAcknowledged":
          setEmpHasAcknowledgedFilter(filter.value);
          break;
        case "dateObj":
          setEmpAckDateFilter(filter.value);
          break;
      }
    }
  };

  const exportEmployees = () => {
    setIsExporting(true);
    apiExportAuthorize({
      year: year,
      filter0: empCodeFilter,
      filter1: empNameFilter,
      filter2: empDivisionFilter,
      filter3: empQuarterFilter,
      filter4: empHasAcknowledgedFilter,
      filter5: empAckDateFilter,
    })
      .then((data) => {
        setIsExporting(false);
        setErrorMessage("");

        const url: string = apiFormExportEmployeesUrl(data.token);
        window.open(url, "_blank");
      })
      .catch((e) => {
        setIsExporting(false);
        setErrorMessage(e.message);
      });
  };

  return (
    <>
      <Backdrop
        open={isLoadingYears || isLoadingEmployees || isExporting}
        style={{ zIndex: 100 }}
      >
        <CircularProgress />
      </Backdrop>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Container maxWidth="xl" style={{ marginTop: 20 }}>
        <Paper>
          <Box p={2}>
            <FormControl size="small">
              <InputLabel id="year-select-label">Year</InputLabel>
              <Select
                labelId="year-select-label"
                label="Year"
                value={year}
                onChange={(e) => setYear(e.target.value as string)}
              >
                {years.map((x) => (
                  <MenuItem value={x}>{x}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Paper>
        <MaterialTable
          columns={[
            {
              title: "Code",
              field: "code",
              grouping: true,
            },
            {
              title: "Name",
              field: "name",
              grouping: true,
            },
            {
              title: "Division",
              field: "division",
              grouping: true,
            },
            {
              title: "Year",
              field: "year",
              grouping: true,
            },
            {
              title: "Quarter",
              field: "currentQuarter",
              grouping: true,
              defaultFilter: currentQuarter,
            },
            {
              title: "Acknowledged",
              field: "hasAcknowledged",
              type: "boolean",
              grouping: true,
            },
            {
              title: "Acknowledged Date",
              field: "dateObj",
              type: "datetime",
              grouping: false,
            },
          ]}
          data={employees}
          onFilterChange={onFilterChange}
          options={{
            filtering: true,
            toolbar: false,
            //draggable: false, (must use for grouping to be enabled)
            grouping: true,

            headerStyle: {
              position: "sticky",
              top: 0,
            },

            maxBodyHeight: errorMessage
              ? windowHeight - 320
              : windowHeight - 280,
            minBodyHeight: errorMessage
              ? windowHeight - 320
              : windowHeight - 280,

            emptyRowsWhenPaging: false,
            pageSize: 20,
            pageSizeOptions: [10, 20, 30],
            tableLayout: "auto",
            loadingType: "linear",
            debounceInterval: 450,
            padding: "dense",
          }}
          components={{
            Pagination: (props) => (
              <div style={{ display: "flex" }}>
                <Box margin={1} style={{ flexGrow: 1 }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    startIcon={<GetAppIcon />}
                    onClick={exportEmployees}
                  >
                    Export to Excel
                  </Button>
                </Box>
                <TablePagination {...props} />
              </div>
            ),
          }}
        />
      </Container>
    </>
  );
}
