import { Alert, LinearProgress } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { apiGetActiveEmployee, apiSubmitAcknowledgement } from "../api";
import AcknowledgmentForm from "../Components/AcknowledgmentForm";

enum HomePageState {
  Idle,
  Loading,
  Submitting,
  Error,
  Unavailable,
  NotAcknowledged,
  Acknowledged,
}

export default function HomePage(): ReactElement {
  const [state, setState] = useState<HomePageState>(HomePageState.Idle);
  const [errorMessage, setErrorMessage] = useState("");

  const [empCode, setEmpCode] = useState("");
  const [empName, setEmpName] = useState("");
  const [empEmail, setEmpEmail] = useState("");
  const [date, setDate] = useState("");
  const [currentQuarter, setCurrentQuarter] = useState("");
  const [nextQuarter, setNextQuarter] = useState("");
  const [nextQuarterDate, setNextQuarterDate] = useState("");

  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    setState(HomePageState.Loading);
    apiGetActiveEmployee()
      .then((data) => {
        setEmpCode(data.code);
        setEmpName(data.name);
        setEmpEmail(data.email);
        setDate(data.date);
        setCurrentQuarter(data.currentQuarter);
        setNextQuarter(data.nextQuarter);
        setNextQuarterDate(data.nextQuarterDate);

        if (!data.currentQuarter) {
          setState(HomePageState.Unavailable);
        } else if (!data.hasAcknowledged) {
          setState(HomePageState.NotAcknowledged);
        } else {
          setState(HomePageState.Acknowledged);
        }
      })
      .catch((e) => {
        setState(HomePageState.Error);
        setErrorMessage(e.message);
      });
  }, []);

  const submitForm = () => {
    setState(HomePageState.Submitting);
    apiSubmitAcknowledgement({ comments: "" })
      .then(() => {
        setState(HomePageState.Acknowledged);
      })
      .catch((e) => {
        setState(HomePageState.Error);
        setErrorMessage(e.message);
      });
  };

  switch (state) {
    case HomePageState.Loading:
      return <LinearProgress />;
    case HomePageState.Submitting:
      return <LinearProgress />;
    case HomePageState.Error:
      return <Alert severity="error">{errorMessage}</Alert>;
    case HomePageState.Unavailable:
      return (
        <Alert severity="info">
          The acknowledgment period for quarter <strong>{nextQuarter}</strong>{" "}
          will start on <strong>{nextQuarterDate}</strong>.
        </Alert>
      );
    case HomePageState.NotAcknowledged:
      return (
        <AcknowledgmentForm
          empNo={empCode}
          empName={empName}
          empEmail={empEmail}
          quarter={currentQuarter}
          date={date}
          isChecked={isConfirmed}
          onCheckedChanged={(v) => setIsConfirmed(v)}
          onSubmitClicked={submitForm}
        />
      );
    case HomePageState.Acknowledged:
      return (
        <Alert severity="success">
          You submitted your acknowledgment for the quarter{" "}
          <strong>{currentQuarter}</strong> on <strong>{date}</strong>.
        </Alert>
      );
    default:
      return <></>;
  }
}
