import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { ReactElement } from "react";

interface AcknowledgmentFormProps {
  empNo: string;
  empName: string;
  empEmail: string;
  quarter: string;
  date: string;
  isChecked: boolean;
  onCheckedChanged: (value: boolean) => void;
  onSubmitClicked: () => void;
}

export default function AcknowledgmentForm(
  props: AcknowledgmentFormProps
): ReactElement {
  return (
    <Container maxWidth="md" style={{ marginTop: 30, marginBottom: 30 }}>
      <Paper elevation={3}>
        <Box p={3}>
          <Grid container alignContent="center" spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Employee No."
                fullWidth
                value={props.empNo}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Employee Name"
                fullWidth
                value={props.empName}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="E-mail"
                fullWidth
                value={props.empEmail}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Date"
                fullWidth
                value={props.date}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
              <Alert severity="info" icon={false}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="confirmBox"
                      checked={props.isChecked}
                      onChange={(e) => props.onCheckedChanged(e.target.checked)}
                    />
                  }
                  label="I hereby confirm that electronic or hard copy business files and data that are in my charge have been reviewed and are in conformance with the DeGolyer and MacNaughton Records Retention Policy. This includes business items in my company office, on any electronic storage device or computer I control and has been used for business, business items removed from company premises, company assigned computers, and other locations where business items might exist by my actions."
                />
              </Alert>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                disabled={!props.isChecked}
                variant="contained"
                color="primary"
                onClick={props.onSubmitClicked}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
