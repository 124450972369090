import { getAccessToken } from "./auth";

const ApiPrefix: string = process.env.REACT_APP_API_URL_PREFIX || "";

type Params = Record<string, string>;

function formUrl(path: string, params?: Params): string {
  const url: URL = new URL(path, ApiPrefix);
  for (const name in params) {
    url.searchParams.append(name, params[name]);
  }
  return url.toString();
}

interface ApiCallParams {
  method: string;
  path: string;
  body?: object;
  params?: Params;
}

async function apiCallInner(params: ApiCallParams): Promise<Response> {
  const url: string = formUrl(params.path, params.params);
  const accessToken: string = await getAccessToken();
  const response: Response = await fetch(url, {
    method: params.method,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params.body),
  });
  return response;
}

interface CurrentUser {
  email: string;
  isAdmin: boolean;
}

export async function apiGetCurrentUser(): Promise<CurrentUser> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "currentuser",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: CurrentUser = await response.json();
  return data;
}

interface YearsCurrentQuarter {
  years: string[];
  currentQuarter: string;
}

export async function apiGetYears(): Promise<YearsCurrentQuarter> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employees/years",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: YearsCurrentQuarter = await response.json();
  return data;
}

interface EmployeesCurrentQuarter {
  employees: Employee[];
  currentQuarter: string;
}

export async function apiGetEmployees(
  year: string
): Promise<EmployeesCurrentQuarter> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employees/list",
    params: { year },
  });
  if (!response.ok) throw Error(response.statusText);
  const data: EmployeesCurrentQuarter = await response.json();
  return data;
}

export interface Employee {
  code: string;
  name: string;
  email: string;
  manager: boolean;
  date: string;
  division: string;
  acknowledgeRequired: boolean;
  hasAcknowledged: boolean;
  currentQuarter: string;
  nextQuarter: string;
  nextQuarterDate: string;
}

export async function apiGetActiveEmployee(): Promise<Employee> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employees/activeuser",
  });
  if (response.status === 400) {
    throw Error("Sorry, you are not authorized to use this form.");
  } else if (!response.ok) {
    throw Error(response.statusText);
  }
  const data: Employee = await response.json();
  return data;
}

interface SubmitAcknowledgementData {
  comments: string;
}

export async function apiSubmitAcknowledgement(
  data: SubmitAcknowledgementData
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "acknowledge",
    body: data,
  });
  if (!response.ok) throw Error(response.statusText);
}

interface ExportAuthorizeParams {
  year: string;
  filter0: string;
  filter1: string;
  filter2: string;
  filter3: string;
  filter4: string;
  filter5: Date | undefined;
}

interface ExportAuthorizeResult {
  token: string;
}

export async function apiExportAuthorize(
  params: ExportAuthorizeParams
): Promise<ExportAuthorizeResult> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "export/authorize",
    body: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: ExportAuthorizeResult = await response.json();
  return data;
}

export function apiFormExportEmployeesUrl(token: string): string {
  return formUrl("export/employees", { token: token });
}

interface QuarterParams {
  q1: string | Date;
  q2: string | Date;
  q3: string | Date;
  q4: string | Date;
  q1Len: number;
  q2Len: number;
  q3Len: number;
  q4Len: number;
}

export async function apiGetQuarterParams(): Promise<QuarterParams> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "quarterparams",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: QuarterParams = await response.json();
  return data;
}

export async function apiSetQuarterParams(data: QuarterParams): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "quarterparams",
    body: data,
  });
  if (!response.ok) throw Error(response.statusText);
}
