import { useIsAuthenticated } from "@azure/msal-react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { version } from "../../package.json";
import { apiGetCurrentUser } from "../api";

interface HeaderProps {
  onLogoutClick: () => void;
}

export default function Header(props: HeaderProps): ReactElement {
  const isAuthenticated = useIsAuthenticated();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      apiGetCurrentUser()
        .then((data) => {
          setIsAdmin(data.isAdmin === true);
        })
        .catch((e) => {
          console.log("Error:", e);
        });
    }
  }, [isAuthenticated]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <AppBar
      position="static"
      elevation={3}
      style={{ backgroundColor: grey[50] }}
    >
      <Toolbar>
        <Link to="/">
          <img
            src={process.env.PUBLIC_URL + "/dm_logo.png"}
            alt=""
            style={{
              height: "52px",
              verticalAlign: "middle",
              paddingRight: "2rem",
            }}
          />
        </Link>
        <Typography
          variant="subtitle2"
          color="inherit"
          noWrap
          style={{ color: grey[900], flexGrow: 1 }}
        >
          D&amp;M Record Retention
          <Typography
            variant="subtitle2"
            component="span"
            style={{ color: "#666" }}
          >
            &nbsp;version {version}
          </Typography>
        </Typography>
        {isAuthenticated && (
          <Button
            endIcon={<MenuIcon />}
            variant="outlined"
            onClick={openDrawer}
          >
            Menu
          </Button>
        )}
      </Toolbar>
      <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
        <List style={{ width: 250 }}>
          <ListItem
            button
            component={Link}
            to="/"
            key="home"
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </List>
        {isAdmin && (
          <>
            <Divider />
            <List style={{ width: 250 }}>
              <ListItem
                button
                component={Link}
                to="/employees"
                key="employees"
                onClick={closeDrawer}
              >
                <ListItemText primary="Employees" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/quarters"
                key="setquarters"
                onClick={closeDrawer}
              >
                <ListItemText primary="Manage Quarter Dates" />
              </ListItem>
            </List>
          </>
        )}
        <Divider />
        <List>
          <ListItem button key="logout" onClick={props.onLogoutClick}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  );
}
