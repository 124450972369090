import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ReactElement } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { msalInstance } from "./auth";
import Header from "./Components/Header";
import EmployeesPage from "./Pages/EmployeesPage";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import QuartersPage from "./Pages/QuartersPage";
import theme from "./theme";

export default function Application(): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Header onLogoutClick={() => msalInstance.logoutRedirect()} />
          <UnauthenticatedTemplate>
            <LoginPage onLoginClick={() => msalInstance.loginRedirect()} />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/employees" element={<EmployeesPage />} />
              <Route path="/quarters" element={<QuartersPage />} />
            </Routes>
          </AuthenticatedTemplate>
        </BrowserRouter>
      </MsalProvider>
    </ThemeProvider>
  );
}
